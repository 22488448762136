<template>
  <div class="chatlog">
    <ChatLogComponent/>
  </div>
</template>

<script>
import ChatLogComponent from '@/components/ChatLogComponent.vue'

export default {
  name: 'Chatlog',
  components: {
    ChatLogComponent
  }
}
</script>
