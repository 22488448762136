<template>
  <table class="min-w-full rounded-lg">
    <tbody>
    <tr>
      <td class="whitespace-nowrap">
        <div class="flex items-center">
          <img :src="`https://render.hdskins.de/users/skin/${this.message.senderUniqueId}/face`" class="h-10 w-10"/>
          <div class="ml-4">
              <span
                  :class="[
                  'font-medium',
                  this.message.reported ? 'text-red-400' : 'text-white',
                ]"
              >
                [{{ this.displayTimestamp }}] {{ this.message.senderName }} »
                {{ this.message.message }}
              </span>
          </div>
        </div>
      </td>
      <td class="whitespace-nowrap text-white text-right text-sm font-medium">
        {{ this.message.server }}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";

export default {
  name: "MessageComponent",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    displayTimestamp: "",
  }),
  mounted() {
    this.displayTimestamp = moment(this.message.timestamp).format("HH:mm:ss");
  }
};
</script>
