<template>
  <div>
    <h1 v-if="!this.id" class="text-center text-lg font-bold lg:text-2xl">
      Sorry, an error has occurred.<br/>
      No ChatLog was found at this URL.
    </h1>
    <section v-else class="text-gray-700 body-font">
      <div
          class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row"
      >
        <div
            class="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:items-start md:text-left lg:text-center"
        >
          <h2
              class="mb-1 px-1 text-xs font-medium tracking-widest text-white title-font"
          >
            CHATLOG ID: {{ this.id }}
          </h2>
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            ChatLog
          </h1>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >
            Creator: {{ this.creator.name }} <br/>
            Creation Date: {{ this.creationTimestamp }}
          </p>
          <table class="w-full rounded-lg bg-brand-white10">
            <tbody>
            <tr v-for="message in this.messages" :key="message.messageId">
              <td class="px-6 py-3 whitespace-nowrap">
                <MessageComponent :message="message"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent.vue";
import moment from "moment";

export default {
  name: "ChatLogComponent",
  components: {MessageComponent},
  data: () => ({
    creator: {},
    id: "",
    creationTimestamp: 0,
    messages: [],
  }),
  methods: {
    async loadMessages() {
      const chatlogId = this.$route.query["id"];
      try {
        const response = await fetch(
            "https://api.globalchataddon.de/chatlog/" + chatlogId
        );
        const json = await response.json();

        this.id = json.id;
        this.creator.uuid = json.creator;
        this.creator.name = json.creatorName;
        this.creationTimestamp = moment(json.creationTimestamp).format(
            "DD.MM.YYYY HH:mm"
        );

        this.messages = [];

        json.messages.forEach((message) =>
            this.messages.push({
              messageId: message.id,
              reported: json.reportedMessage === message.id,
              senderName: message.senderName,
              senderUniqueId: message.senderUniqueId,
              message: message.text,
              timestamp: message.timestamp,
              server: message.server,
            })
        );
      } catch (error) {
        console.error("Failed to request messages", error);
      }
    },
  },
  async mounted() {
    this.loadMessages();
  },
};
</script>
